import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Form from './pages/Form';
import Success from "./pages/Success";
import Failure from "./pages/Failure";
import Login from "./pages/Login";

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/form" element={<Form />} />
              <Route path="/success" element={<Success />} />
              <Route path="/failure" element={<Failure />} />
          </Routes>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
