import styles from './styles.module.css'
import {Section} from "../Section";

export const ReadOnly = ({title, value}) => {
    return (
        <Section
            title={title}
        >
            <div className={styles.value}>{value}</div>
        </Section>
    )
}
