import styles from './styles.module.css'
import cx from "classnames";
import {Title} from "../Title";

export const Section = ({error, title, optional, children, focusRef}) => {
    const onSectionClick =  () => {
        if (focusRef && focusRef.current) {
            focusRef.current.focus()
        }
    }

    return (
        <div
            className={cx(styles.section, {[styles.error]: error})}
            onClick={onSectionClick}
        >
            <Title title={title} optional={optional}/>
            {children}
            {error && <div className={styles.message}>{error}</div>}
        </div>
    )
}
