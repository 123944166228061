import {useNavigate} from "react-router-dom";
import Page from "../../components/Page";
import {useState} from "react";
import {Button} from "../../components/Button";
import {Input} from "../../components/Input";
import {Error} from "../../components/Error";
import styles from './styles.module.css';

function Success() {
    const navigate = useNavigate()

    const [password, setPassword] = useState('')

    const [passwordError, setPasswordError] = useState('')
    const [authError, setAuthError] = useState('')

    const [logging, setLogging] = useState(false)

    const onChangePassword = (password) => {
        setAuthError('')
        setPasswordError('')
        setPassword(password)
    }

    const onSubmit = async () => {
        if (!password) {
            setPasswordError('Password required')
            return
        }

        setLogging(true)

        try {
            const response = await fetch('/login', {
                method: 'post',
                body: JSON.stringify({password}),
                headers: {'Content-Type': 'application/json'},
            })

            if (response.status !== 200) {
                throw new Error('Authentication error')
            }

            const payload = await response.json()
            navigate('/form', {state: payload})
        } catch (error) {
            setAuthError('Incorrect login or password. Try again')
        }

        setLogging(false)
    }

    return (
        <Page subtitle="Please log in">
            {authError && <Error error={authError}/>}
            <form className={styles.form} onSubmit={onSubmit}>
                <Input
                    name="password"
                    value={password}
                    title="Password"
                    onChange={onChangePassword}
                    error={passwordError}
                    password
                    autoFocus
                />
                <Button
                    title="Log in"
                    onClick={onSubmit}
                    disabled={logging}
                />
            </form>
        </Page>
    );
}

export default Success;
