import {Date} from "../../components/Date";
import moment from "moment";
import {useState} from "react";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {Time} from "../../components/Time";
import {Duration} from "../../components/Duration";
import {TextArea} from "../../components/TextArea";
import {Button} from "../../components/Button";
import Page from "../../components/Page";
import {ReadOnly} from "../../components/ReadOnly";
import styles from './styles.module.css';

function Form() {
    const navigate = useNavigate()
    const [date, setDate] = useState(moment())
    const [timeIn, setTimeIn] = useState(moment({hours: 9, minutes: 0}))
    const [timeOut, setTimeOut] = useState(moment({hours: 18, minutes: 0}))
    const [notes, setNotes] = useState('')
    const [lunch, setLunch] = useState('00:00')

    const [timeOutError, setTimeOutError] = useState('')

    const [posting, setPosting] = useState(false)

    const { state } = useLocation()

    if (!state) {
        return <Navigate to='/' />
    }

    const {name, token} = state

    const checkErrors = () => {
        let thereAreErrors = false

        if (timeOut.isBefore(timeIn)) {
            setTimeOutError('Time out must be later than time in')
            thereAreErrors = true
        }

        return thereAreErrors
    }

    const onSubmit = async () => {
        if (checkErrors()) {
            return
        }

        const lunchParts = lunch.split(':')

        const data = {
            token,
            date: {year: date.year(), month: date.month(), date: date.date()},
            timeIn: {hours: timeIn.hours(), minutes: timeIn.minutes()},
            timeOut: {hours: timeOut.hours(), minutes: timeOut.minutes()},
            lunch: {hours: lunchParts[0], minutes: lunchParts[1]},
            notes,
        }

        setPosting(true)

        try {
            await fetch('/postTimesheet', {
                method: 'post',
                body: JSON.stringify(data),
                headers: {'Content-Type': 'application/json'},
            })

            navigate('/success', {state})
        } catch (error) {
            navigate('/failure', {state})
        }

        setPosting(false)
    }

    return (
        <Page subtitle="Please log time here" >
            <form className={styles.form} >
                <ReadOnly
                    title="Name"
                    value={name}
                />
                <Date
                    title="Date"
                    date={date}
                    onChange={setDate}
                    onEnter={onSubmit}
                />
                <Time
                    title="Time In"
                    time={timeIn}
                    onChange={setTimeIn}
                    onEnter={onSubmit}
                />
                <Duration
                    title="Lunch / Break"
                    duration={lunch}
                    onChange={setLunch}
                    onEnter={onSubmit}
                />
                <Time
                    error={timeOutError}
                    title="Time Out"
                    time={timeOut}
                    onChange={(value) => {
                        setTimeOut(value)
                        setTimeOutError('')
                    }}
                    onEnter={onSubmit}
                />
                <TextArea
                    title="Notes"
                    value={notes}
                    onChange={setNotes}
                    onEnter={onSubmit}
                />
                <Button
                    title="Send"
                    onClick={onSubmit}
                    disabled={posting}
                />
            </form>
        </Page>
    );
}

export default Form;
