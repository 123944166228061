import styles from './styles.module.css'
import {Select} from "../Select";
import {padStart, range} from "lodash";
import moment from "moment";
import {Section} from "../Section";
import {useRef} from "react";

export const Date = ({error, title, date, onChange, onEnter}) => {
    const focusRef = useRef()
    const now = moment()

    const dates = range(1, date.daysInMonth() + 1).map((item) => {
        return {
            value: item,
            option: padStart(item, 2, '0'),
            label: padStart(item, 2, '0'),
        }
    })

    const months = [
        {option: 'January', value: 0, label: '01'},
        {option: 'February', value: 1, label: '02'},
        {option: 'March', value: 2, label: '03'},
        {option: 'April', value: 3, label: '04'},
        {option: 'May', value: 4, label: '05'},
        {option: 'June', value: 5, label: '06'},
        {option: 'July', value: 6, label: '07'},
        {option: 'August', value: 7, label: '08'},
        {option: 'September', value: 8, label: '09'},
        {option: 'October', value: 9, label: '10'},
        {option: 'November', value: 10, label: '11'},
        {option: 'December', value: 11, label: '12'},
    ]

    const years = range(now.year() - 1, now.year() + 2).map((item) => {
        return {
            value: item,
            option: item,
            label: item,
        }
    })

    const dateOption = dates.find(({value}) => value === date.date())
    const monthOption = months.find(({value}) => value === date.month())
    const yearOption = years.find(({value}) => value === date.year())

    const onChangeDate = (option) => onChange(moment({year: date.year(), month: date.month(), date: option.value}))

    const onChangeMonth = (option) => {
        const newDate = moment({year: date.year(), month: option.value})
        newDate.date(date.date() <= newDate.daysInMonth() ? date.date() : newDate.daysInMonth())
        onChange(newDate)
    }

    const onChangeYear = (option) => {
        const newDate = moment({month: date.month(), year: option.value})
        newDate.date(date.date() <= newDate.daysInMonth() ? date.date() : newDate.daysInMonth())
        onChange(newDate)
    }

    return (
        <Section
            error={error}
            title={title}
            focusRef={focusRef}
        >
            <div className={styles.date}>
                <Select
                    options={months}
                    option={monthOption}
                    onChange={onChangeMonth}
                    onEnter={onEnter}
                    width="20px"
                />
                <div>/</div>
                <Select
                    focusRef={focusRef}
                    options={dates}
                    option={dateOption}
                    onChange={onChangeDate}
                    onEnter={onEnter}
                    width="20px"
                />
                <div>/</div>
                <Select
                    options={years}
                    option={yearOption}
                    onChange={onChangeYear}
                    onEnter={onEnter}
                    width="40px"
                />
            </div>
        </Section>
    )
}
