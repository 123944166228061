import styles from './styles.module.css'
import cx from "classnames";
import {Section} from "../Section";
import {useRef} from "react";

export const TextArea = ({title, value, onChange, onEnter, error}) => {
    const onKeyDown = (event) => {
        if (event.ctrlKey && event.keyCode === 13) {
            onEnter()
        }
    }

    const onInput = (event) => {
        const textarea = event.target
        const linesQty = textarea.value.split('\n').length
        textarea.style.height = 24 * linesQty + "px"
    }

    const focusRef = useRef()

    return (
        <Section
            optional
            title={title}
            focusRef={focusRef}
        >
            <textarea
                ref={focusRef}
                onKeyDown={onKeyDown}
                onInput={onInput}
                className={cx(styles.textarea, {[styles.error]: error})}
                value={value}
                onChange={(event) => onChange(event.target.value)}
            />
        </Section>
    )
}
