import styles from './styles.module.css';
import {Link, useLocation} from "react-router-dom";
import Page from "../../components/Page";

function Success() {
    const { state } = useLocation()
    return (
        <Page subtitle="Your response has been recorded">
            <Link className={styles.description} to='/form' state={state} >Submit another response</Link>
        </Page>
    );
}

export default Success;
