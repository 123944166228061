import styles from './styles.module.css'
import {Select} from "../Select";
import {padStart, range} from "lodash";
import moment from "moment";
import {Section} from "../Section";
import {useRef} from "react";

export const Time = ({error, title, time, onChange, onEnter}) => {
    const focusRef = useRef()

    const hours = range(0, 24).map((item) => {
        return {
            value: item,
            option: padStart(item, 2, '0'),
            label: padStart(item, 2, '0'),
        }
    })

    const minutes = range(0, 60, 5).map((item) => {
        return {
            value: item,
            option: padStart(item, 2, '0'),
            label: padStart(item, 2, '0'),
        }
    })

    const hoursOption = hours.find(({value}) => value === time.hours())
    const minutesOption = minutes.find(({value}) => value === time.minutes())

    const onChangeMinutes = (option) => onChange(moment({hours: time.hours(), minutes: option.value}))
    const onChangeHours = (option) => onChange(moment({hours: option.value, minutes: time.minutes()}))

    return (
        <Section focusRef={focusRef} title={title} error={error}>
            <div className={styles.time}>
                <Select
                    focusRef={focusRef}
                    options={hours}
                    option={hoursOption}
                    onChange={onChangeHours}
                    onEnter={onEnter}
                    width="20px"
                />
                <div>:</div>
                <Select
                    options={minutes}
                    option={minutesOption}
                    onChange={onChangeMinutes}
                    onEnter={onEnter}
                    width="20px"
                />
            </div>
        </Section>
    )
}
