import styles from './styles.module.css'

export const Button = ({title, onClick, disabled}) => {
    return (
        <button
            onClick={() => onClick()}
            className={styles.submit}
            disabled={disabled}
        >
            {disabled ? <div className={styles.spinner}/> : <span>{title}</span>}
        </button>
    )
}
