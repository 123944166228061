import styles from './styles.module.css'
import {useEffect, useRef, useState} from "react";
import cx from 'classnames'

export const Select = ({
                           option,
                           options,
                           maxRowNumber = 5,
                           onChange,
                           focusRef,
                           width,
                           onEnter,
}) => {
    const [shown, setShown] = useState(false)
    const [previousOption, setPreviousOption] = useState(option)

    const optionsRef = useRef()
    const innerFocusRef = useRef()
    const resultFocusRef = focusRef || innerFocusRef

    const onInputFocus = () => {
        setShown(true)
    }

    const onInputBlur = () => {
        setShown(false)
    }

    const onSelect = (event, newOption) => {
        setPreviousOption(option)
        onChange(newOption)
    }

    const onKeyDown = (event) => {
        if (event.key === 'Tab') {
            return
        }

        if (event.ctrlKey && event.key === 'Enter') {
            onEnter && onEnter()
            return
        }

        if (!['ArrowUp', 'ArrowDown'].includes(event.key)) {
            return
        }

        const shift = event.key === 'ArrowUp' ? -1 : 1
        const optionNumber = options.length
        const currentIndex = option ? options.findIndex(({value}) => value === option.value) : 0
        const newIndex = ((currentIndex + shift) % optionNumber + optionNumber) % optionNumber
        const newOption = options[newIndex]
        setPreviousOption(option)
        onChange(newOption)
        event.preventDefault()
    }

    const lineHeight = 24
    const rowNumber = options.length < maxRowNumber ? options.length : maxRowNumber
    const optionsBlockHeight = rowNumber * lineHeight

    useEffect(() => {
        if (!optionsRef || !optionsRef.current) {
            return
        }

        if (!option) {
            return
        }

        const previousIndex = options.findIndex((item) => item.value === previousOption.value)
        const index = options.findIndex((item) => item.value === option.value)

        if (previousIndex === index) {
            return
        }

        const optionTop = index * lineHeight
        const optionBottom = (index + 1) * lineHeight

        if (
            optionTop < optionsRef.current.scrollTop
            || optionBottom > optionsRef.current.scrollTop + optionsBlockHeight
        ) {
            if (index === 0) {
                optionsRef.current.scrollTop = optionTop
                return
            }

            if (index === options.length - 1) {
                optionsRef.current.scrollTop = optionBottom
                return
            }

            optionsRef.current.scrollTop = (previousIndex < index) ? optionTop : (optionBottom - optionsBlockHeight)
        }

    }, [option, optionsBlockHeight, shown])

    return (
        <div className={styles.wrapper}>
            <div
                tabIndex="0"
                ref={resultFocusRef}
                className={cx(styles.input, {[styles.active]: shown})}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                onClick={(event) => event.stopPropagation()}
                onKeyDown={(event) => onKeyDown(event)}
                style={width ? {width} : {}}

            >
                <span>{option ? option.label : ''}</span>
                {shown && (
                    <div ref={optionsRef} className={styles.options} style={{height: optionsBlockHeight + 'px'}}>
                        {options.map((item) => {
                            return (
                                <div
                                    key={item.value}
                                    className={cx(styles.option, {[styles.active]: option && item.value === option.value})}
                                    onMouseDown={(event) => onSelect(event, item)}
                                >
                                    {item.option}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>

    )
}
