import styles from './styles.module.css';
import {Link, useLocation} from "react-router-dom";
import Page from "../../components/Page";

function Failure() {
    const { state } = useLocation()
    return (
        <Page subtitle="Something went wrong">
            <Link className={styles.description} to='/form' state={state}>Try again</Link>
        </Page>
    );
}

export default Failure;
