import styles from './styles.module.css'
import {Section} from "../Section";
import {useRef} from "react";

export const Input = ({title, value, onChange, error, password, name, autoFocus}) => {
    const focusRef = useRef()

    return (
        <Section
            error={error}
            title={title}
            focusRef={focusRef}
        >
            <input
                name={name}
                ref={focusRef}
                className={styles.input}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                type={password ? 'password' : 'text'}
                autoFocus={autoFocus}
            />
        </Section>
    )
}
