import styles from './styles.module.css';

function Page({children, subtitle}) {
    return (
        <div className={styles.app}>
            <div className={styles.left}>
                <div className={styles.logo} />
                <div className={styles.header}>OPS Timesheet</div>
                <div className={styles.description}>{subtitle}</div>
                {children}
            </div>
            <div className={styles.right}>
                <div className={styles.pic} />
                <div className={styles.logo} />
            </div>
        </div>
    );
}

export default Page;
