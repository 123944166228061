import styles from './styles.module.css'

export const Title = ({title, optional}) => {
    return (
        <div className={styles.title}>
            <div>{title}</div>
            {optional && <div className={styles.optional}>(optional)</div>}
        </div>
    )
}
