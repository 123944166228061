import styles from './styles.module.css'
import {Select} from "../Select";
import {Section} from "../Section";
import {useRef} from "react";

export const Duration = ({error, title, duration, onChange, onEnter}) => {
    const options = [
        {value: '00:00', option: 'w/o lunch', label: 'w/o lunch'},
        {value: '00:15', option: '15 min', label: '15 min'},
        {value: '00:30', option: '30 min', label: '30 min'},
        {value: '00:45', option: '45 min', label: '45 min'},
        {value: '01:00', option: '1 hour', label: '1 hour'},
        {value: '01:30', option: '1 hour 30 min', label: '1 hour 30 min'},
    ]

    const option = options.find(({value}) => value === duration)

    const focusRef = useRef()

    const onChangeOption = (option) => {
        onChange(option.value)
    }


    return (
        <Section
            error={error}
            title={title}
            optional
            focusRef={focusRef}
        >
            <div className={styles.time}>
                <Select
                    focusRef={focusRef}
                    options={options}
                    option={option}
                    onChange={onChangeOption}
                    onEnter={onEnter}
                />
            </div>
        </Section>
    )
}
